import React, { useEffect } from "react";

function MyFest(codigoQR,email,pass,ruta) {

  useEffect(() => {
    console.log("que viene");  
    window.location.href = `${ruta}/detalle/${codigoQR}/${email}/${pass}`;
  }, []);

  return (
    <div>
      <h2>My Fest</h2>
    </div>
  );
}

export default  MyFest;