import React, { useState, useEffect } from "react";
import festInit from "./MyFest";
import mySocialImage from "./images/splashN1.jpg";
import mySocialImageCargando from "./images/splashN1cargando.gif";


import { db } from "./Firebase"; // update with your path to firestore config
import { doc, getDoc } from "firebase/firestore";
import QrReader from "react-qr-reader";

// import textByLine from "./policy.txt";

function FirstRender() {
  const [condition, setCondition] = useState(false);
  const [codigoQR, setCodigoQR] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [ruta, setRuta] = useState("Sin Datos");
  const [fiesta, setFiesta] = useState("Sin Datos");
  const [watching, setWatching] = useState(false);
  const [noIniciarAuto, setNoIniciarAuto] = useState(false);
  const [refrescar, setRefrescar] = useState(false);
  const [deshabilitado, setDeshabilitado] = useState("disabled");
  const [mostrarBoton, setMostrarBoton] = useState(false);
  const [pasar,setPasar] = useState(false);

 var  politicas = `TÉRMINOS DE USO Y/O POLÍTICA DE USUARIO DE LA APLICACIÓN
 
 LICENCIA DE USO 
 
 Esta licencia de uso es otorgada por MySocialFest, (en adelante, el "Licenciante") a la persona a quien se haya invitado al evento denominado "MySocialFest Event" (en adelante, el "Usuario"), y que haya recibido un código único para activar la aplicación "MySocialFest App" (en adelante, la "Aplicación"). La Aplicación sólo podrá ser utilizada por el Usuario en los términos, con los alcances y limitaciones que se indican a continuación.
 
 
 Extensión de la licencia
 
 La presente licencia de uso (en adelante, la "Licencia") es gratuita, personal, limitada, no exclusiva ni transferible, y se refiere únicamente a la Aplicación. No implica la concesión de ninguna otra licencia y/o permiso de uso de ningún otro software.
 La Licencia otorga el derecho de uso de la Aplicación a los fines de la participación del Usuario en el evento social denominado "MySocialFest Event" (en adelante, la "Fiesta").
 La Licencia estará vigente hasta el 31/12/2023.
 
 Descripción de la Aplicación
 La Aplicación permitirá al usuario recibir en su dispositivo móvil notificaciones respecto de la Fiesta, como así también actividades, tanto en forma previa como durante la Fiesta y con posterioridad a ella.
 Las notificaciones pueden incluir información, imágenes, videos o sonido, incluso captadas durante la Fiesta y con participación del Usuario.
 Las actividades pueden incluir juegos de preguntas y respuestas (trivias) y votaciones sobre hechos ocurridos en la Fiesta o sobre aspectos vinculados con la Fiesta. En todos los casos, se tratará de actividades de índole social o familiar, que no tendrán otra finalidad que el entretenimiento del Usuario en la Fiesta.
 
 Contenido generado por el Usuario
 La Aplicación permitirá al Usuario generar contenido, lo que incluye comentarios, imágenes, videos y sonidos (en adelante, el “Contenido Generado por el Usuario”) que deberán guardar una adecuada relación con el evento familiar al que está destinado.
 El Usuario está en conocimiento de que el Contenido Generado por el Usuario estará disponible para su visualización, utilización e interacción por parte de otros usuarios de la Aplicación. Por lo tanto, el Usuario otorga una licencia de uso perpetua y sin límite territorial para que tanto el Licenciante como cualquier otro usuario de la Aplicación puedan interactuar con y/o reproducir y/o comentar y/o distribuir el Contenido Generado por el Usuario en el marco de la Aplicación.
 El Contenido Generado por el Usuario deberá ser lícito y no resultar ofensivo, discriminatorio ni contrario a la moral o a las buenas costumbres. Además, no deberá violar derechos de propiedad intelectual -ni de ningún otro tipo- de terceros. Tampoco podrá incluir imágenes sexuales o de desnudez y/o contenido que incite o promueva la violencia o el odio
 El Usuario podrá denunciar en la Aplicación el contenido generado por otro usuario, quedando entendido que cualquier Contenido Generado por el Usuario que viole los términos previstos en esta licencia podrá ser eliminado de la Aplicación y, eventualmente, podrá ocasionar la exclusión del Usuario que lo generó y la revocación inmediata de la Licencia.
 
 Requisitos técnicos
 Para funcionar, la Aplicación requiere, como mínimo, de los siguientes requisitos técnicos: (i) un dispositivo móvil ; y (ii) una conexión estable a Internet.
 
 Restricciones
 Queda prohibida la utilización de la Aplicación para cualquier finalidad que no sea la prevista en esta Licencia, relacionada con la participación del Usuario en la Fiesta.
 Queda prohibida la sublicencia, comercialización, modificación, adaptación, o traducción, total o parcial, de la Aplicación.
 Los contenidos a los que el Usuario accederá a través de la Aplicación -incluidos el Contenido Generado por el Usuario- son para uso personal y familiar, y pueden contener imágenes y/o audio y/o video en el que aparezcan otros usuarios, también participantes de la Fiesta. Por lo tanto, queda estrictamente prohibido al Usuario reproducir cualquier contenido de la Aplicación salvo en el marco de la misma Aplicación.
 
 Actualizaciones
 La Aplicación podrá ser actualizada por el Licenciante en forma automática.
 
 
 Propiedad Intelectual. Marcas
 El Licenciante es el único titular de la totalidad de los derechos de propiedad intelectual y/o cualquier otro derecho (incluyendo, en particular pero sin limitación marcas, patentes y cualquier otro derecho de propiedad) respecto de la Aplicación.
 Los contenidos de las pantallas y de la Aplicación constituyen propiedad exclusiva del Licenciante, incluyendo sin limitación sus imágenes, diseño, compilaciones, código, sonidos, archivos, marcas, títulos, designaciones, menciones, signos distintivos, logos, y todo aquel material que lo conforma. El Licenciante se reserva todos los derechos sobre tal material, y no cede ni transfiere ningún derecho sobre su propiedad intelectual. El uso indebido y la reproducción total o parcial de los contenidos antes mencionados quedan prohibidos.
 
 Exención de responsabilidad
 
 El Licenciante confiere la presente Licencia en el contexto de una relación familiar o personal, y exclusivamente a los fines de que el Usuario la utilice para mejorar su experiencia con relación a la Fiesta, pero de ningún modo resulta obligatorio para el Usuario utilizar la Aplicación para participar de la Fiesta.
 El Licenciante no responderá en ningún caso por lucro cesante y/o daño moral y/o daño punitivo o incidental, ni asume responsabilidad alguna ante pérdida de datos o información que pudiera darse como consecuencia directa o indirecta de la utilización de la Aplicación.
 Cesión
 
 La presente licencia es intransferible.
 
 Política de privacidad
 La Aplicación sólo recogerá del dispositivo móvil del Usuario los datos que sean estrictamente necesarios para su correcta utilización, a cuyo efecto requerirá del Usuario los permisos correspondientes.
 El Licenciante sólo almacenará dichos datos temporalmente y a los fines de las actividades previstas en la Aplicación durante la Fiesta. Una vez concluidas las actividades, toda la información de los Usuarios será eliminada.
 En caso de que algún Usuario desee ejercer sus derechos de acceso, rectificación, supresión o actualización previstos en la Ley 25.326, deberá dirigirse personalmente o por correo al Licenciante, al domicilio indicado en la presente Licencia.
 El Licenciante no cederá ningún dato personal de los Usuarios a terceros, y sólo utilizará la información que sea estrictamente necesaria para el funcionamiento de la Aplicación durante la Fiesta y a los fines de mejorar la experiencia del Usuario en la Fiesta.
 Al aceptar la presente Licencia mediante la utilización de la Aplicación, el Usuario está en conocimiento y consiente expresamente que el Licenciante pueda tratar su información del modo expresado en esta cláusula, como así también que su imagen, video o voz, o sus opiniones -en su caso- puedan ser compartidas en el marco de la Aplicación y a los efectos de las actividades previstas en la Fiesta.
 
 Ley y jurisdicción
 Cualquier controversia derivada de la interpretación y/o aplicación de la presente Licencia, será dirimida en forma exclusiva por los Tribunales Ordinarios de la Ciudad de Buenos Aires. A la presente Licencia se aplicará únicamente la legislación vigente en la República Argentina.
 
 
     Contacto 
 
 Si tiene alguna duda o consulta sobre estos términos y condiciones , puede comunicarse por correo a:  email: mysocialfest.info@itbg.com.ar
 
 
 
 Puede visualizar esta página en nuestro website:
 
 https://mysocialfest.com.ar/policy.html
 `
  useEffect(() => {
    if (condition) {
      var currentLocation = window.location.href;
      console.log("que viene");
      window.location.href = `${ruta}/detalle/${codigoQR}/${email}/${pass}`;
      window.setTimeout(function () {
        eliminaEstado();
        window.location = currentLocation;
      }, 1500);
    }
  }, [condition]);

  function guardaEstado(initQR) {
    const fecha = Date.now();
    localStorage.setItem(
      "sessionID",
      JSON.stringify({ codigoQR: initQR, fecha: fecha })
    );
  }

  function habilitaBoton() {
    setDeshabilitado("");
    setMostrarBoton(!mostrarBoton);
  }

  function guardaEstadoPoliticas() {
    const fecha = Date.now();
    localStorage.setItem(
      "acceptPolicy",
      JSON.stringify({ acceptPolicy: true, fecha: fecha })
    );
    setPasar(true);
    setRefrescar(true);
  }

  function verificaEstado() {
    const result = JSON.parse(localStorage.getItem("sessionID"));

    if (result) {
      console.log("Usuario ya entró", result);
      return result.codigoQR;
    } else {
      return false;
    }
  }

  function verificaAceptacionPoliticas() {
    const result = JSON.parse(localStorage.getItem("acceptPolicy"));

    if (result) {
      console.log("Politicas Aceptadas", result);
      return true;
    } else {
      return false;
    }
  }

  function eliminaEstado() {
    setNoIniciarAuto(true);
    localStorage.removeItem("sessionID");
    window.location.reload();
  }

  const mostrarLectorQR = () => {
    scrollToTop();
    setWatching(!watching);
  };

  const handleScan = (data) => {
    var queviene = "";
    if (data) {
      console.log("escaneado->", data);
      queviene = data.trim();

      if (queviene.length < 9 || queviene.length > 11 ) {
        console.log("codigo incorrecto");
      } else {
        console.log("codigo CORRECTO");
        setCodigoQR(queviene);
        getFestDoc(queviene);
      }
      console.log(queviene);
      setWatching(false);

      //   auth
      //     .signInWithEmailAndPassword(usuario.usuario, usuario.codigo)
      //     .catch((error) => alert(error.message));
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  }

  const validarCodigo = (e) => {
    e.preventDefault();
    setCodigoQR(e.target.value);
  };

  const getFestDoc = async (id) => {
    //
    if (id === "") {
      return null;
    }
    const festSnapshot = await getDoc(doc(db, "rutas", id));
    if (festSnapshot.exists()) {
      guardaEstado(id);
      console.log(festSnapshot.data());
      setRuta(festSnapshot.data().ruta);
      setEmail(festSnapshot.data().usuario);
      setPass(festSnapshot.data().clave);
      setCodigoQR(id);
      setCondition(true);
      setFiesta(festSnapshot.data().fiesta);
    } else {
      setRuta("FIESTA NO ENCONTRADA");
      setFiesta("FIESTA NO ENCONTRADA");
    }
  };

  function lanzarTimer(codigo) {
    const timer = setTimeout(() => {
      iniciarAuto(codigo);
    }, 2000);
    return () => clearTimeout(timer);
  }

  function iniciarAuto(codigo) {
    if (noIniciarAuto) {
    } else {
      getFestDoc(codigo);
    }
  }

  const muestraBoton = () => (
    <button
      disabled={deshabilitado}
      className="App-button"
      onClick={() => guardaEstadoPoliticas()}
    >
      {" "}
      Aceptar
    </button>
  );
  //eliminaEstado();
  //verifico si acepta las politicas

  console.log("******************************************************");
  var acepto = verificaAceptacionPoliticas();

   if (!acepto) {
   // if (!pasar) {  
    const texto = politicas;
    return (
      <div className="App">
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}

          <img src={mySocialImage} className="App-image" alt="MySocialFest" />
        </header>
        <div className="App-div-central">
          <p style={{margin:"20px"}}>TÉRMINOS DE USO Y/O POLÍTICA DE USUARIO DE LA APLICACIÓN</p>
          <textarea rows={10} cols={40} defaultValue={texto} readonly="true">  
          </textarea>
          
          <div>
            <br></br>
            <input
              type="checkbox"
              // defaultChecked={this.state.complete}

              onChange={() => habilitaBoton()}
            />
            Hé leido y acepto <br></br>
            los términos y condiciones
          </div>
          <br></br>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <button className="App-button" onClick={() => eliminaEstado()}>
                      {" "}
                      Cancelar 
                  </button> */}
            {/* <label > */}

            {/* </label> */}
            {mostrarBoton ? muestraBoton() : null}
            {/* <button disabled={deshabilitado} className="App-button" onClick={() => guardaEstadoPoliticas()} >
                      {" "}
                      Aceptar
                  </button> */}
          </div>
        </div>
      </div>
    );
  }

  //verifico si es codigo esta local
  const codigo = verificaEstado();
  if (codigo) {
    //validar codigo
    lanzarTimer(codigo);
    return (
      <div className="App">
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}

          <img src={mySocialImageCargando} loading="lazy" className="App-image" alt="MySocialFest" />
        </header>
        <div className="App-div-central">
          {/* <button className="App-button" onClick={() => eliminaEstado()}>
            {" "}
            Cancelar Inicio
          </button> */}
        </div>
      </div>
    );
    // setCodigoQR(codigo); esto hace rerenders eternos (investigarlo)
    // getFestDoc(codigo);
    // return;
  }

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}

        <img src={mySocialImage} className="App-image" alt="MySocialFest" />
      </header>

      <div className="App-div-central">
        <div>
          {/* --------------------------------- LECTOR DE CODIGO QR -------------------------------*/}
          {watching ? (
            <div className="App-QR">
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "100%" }}
              />
              {/* Muestra el resultado del qr <p>{result}</p> */}
            </div>
          ) : (
            <div>
              {/* <button onClick={() => setWatching(true)}>Scan</button> */}
              {/* <h4>value: {result}</h4> */}
            </div>
          )}
        </div>
        <button className="App-button" onClick={() => mostrarLectorQR()}>
          {" "}
          ESCANEA EL CÓDIGO QR
        </button>
        <br></br>
        <p>o ingresa tu código</p>
        <input
          key="asdasdf"
          id="asdasddddd"
          type="text"
          className="App-input"
          name="codigoQR"
          value={codigoQR}
          size="13"
          onChange={validarCodigo}
        />
        <br></br>
        <br></br>
        <button className="App-button" onClick={() => getFestDoc(codigoQR)}>
          {" "}
          INGRESAR
        </button>
        <br></br>
        <br></br>
        <label>{fiesta}</label>
      </div>
    </div>
  );
}

export default FirstRender;
