import React, { useState, useEffect } from "react";
import FirstRender from "./FirstRender";


function Logout(){
    localStorage.removeItem("sessionID" );
    return (<FirstRender />);
}


export default Logout;