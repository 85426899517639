import React, { useState, useEffect } from "react";
import mySocialImage from './images/splashN1.png';
import './App.css';
import FirstRender from "./FirstRender";
import Logout from "./Logout";

import { BrowserRouter, Routes, Route, Navigate,useNavigate } from 'react-router-dom';
import {axios} from "axios";
import { Button, Avatar, makeStyles, Modal, Input } from "@material-ui/core";



function App() {

  const Home = () => {
    const navigate = useNavigate();
    return (
        
        navigate('www.itbg.com.ar')
        
    );
  };
  const ErrorPage = () => {
    const navigate = useNavigate();
    return (
        
      <Navigate to='http://www.itbg.com.ar'/>
        
    );
  };

return(
  <BrowserRouter>
  <Routes>
      <Route
          path="/"
          element={ <FirstRender /> } //hacer un componente igual a este econ otro nombre
      />
      {/* The next line is very important for the Navigate component to work */}
      <Route
          path="/error-page"
          element={ <ErrorPage /> }
      />
      <Route
          path="/redirect"
          element={ <Navigate to="/error-page" /> }
      />
        <Route
          path="/logout"
          element={ <Logout /> }
      />
  </Routes>
  </BrowserRouter>
  );


}
export default App;
